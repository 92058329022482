<template>
  <div id="password-reset">
    <h1 aria-label="Password Reset">
      {{ title }}
    </h1>
    <b-form
      v-if="!emailSent"
      class="form-container"
      @submit.prevent
    >
      <FormProgressBar :progress="progress()" />
      <!-- ERRORS -->
      <FormErrors />
      <div class="form">
        <!-- == EMAIL == -->
        <FormSection
          description="Please enter your email address, and we will send you an email with password reset instructions."
        >
          <FormGroup
            for="email"
            label="Email Address"
            class="w-100"
          >
            <FormInput
              v-validate="{ required: true, email: true }"
              :state="validateState('Email')"
              name="Email"
              bind-id="email"
              get="email"
              set="UPDATE_EMAIL"
              invalid-feedback="Please enter a valid Email."
              invalid-feedback-id="email-invalid-feedback"
              @keyup.enter.native="submit"
            />
          </FormGroup>
        </FormSection>
      </div>

      <b-button
        :disabled="submitButtonDisabled"
        class="signup-buttons w-50-100"
        size="lg"
        variant="primary"
        @click="submit"
      >
        Submit
      </b-button>
    </b-form>
    <div v-else>
      <p class="pb-4">
        An email has been sent to your email address. Follow the directions in the email to reset your password.
      </p>
      <b-button
        class="w-25"
        size="lg"
        variant="primary"
        @click="done"
      >
        Done
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ValidationMixin from '../../mixin/ValidationMixin';
import FormErrors from '../Form/FormErrors.vue';
import FormSection from '../Form/FormSection.vue';
import FormGroup from '../Form/FormGroup.vue';
import FormProgressBar from '../Form/FormProgressBar.vue';
import FormInput from '../Form/FormInput.vue';
import AiraError from '../../utils/AiraError';

export default {
  name: 'PasswordReset',
  components: {
    FormErrors,
    FormSection,
    FormGroup,
    FormProgressBar,
    FormInput,
  },
  mixins: [
    ValidationMixin,
  ],
  data() {
    return {
      pageName: 'passwordReset',
      submitButtonDisabled: false,
      emailSent: false,
    };
  },
  computed: {
    ...mapGetters([
      'email',
      'errors',
    ]),
    title() {
      if (this.emailSent) return 'Password reset email sent';
      return 'Password Reset';
    },
  },
  methods: {
    progress() { return { full: true }; },
    async submit() {
      this.submitButtonDisabled = true;
      if (this.errors.length >= 1) this.$store.commit('CLEAR_ERRORS');

      // first validate fields
      let isValidated = false;
      try {
        isValidated = await this.$validator.validateAll();
        this.$session.set('email', this.email);
      } catch (e) {
        // fail-safe no-op
        console.trace('PasswordReset', e);
      }

      if (!isValidated) {
        const fieldErrors = await this.veeErrors.items.map((item) => item.msg);
        this.$store.commit('PUSH_ERRORS', fieldErrors);
        this.submitButtonDisabled = false;
        return;
      }

      // then hit platform API
      try {
        await this.$store.dispatch('passwordReset', { email: this.email });
        this.emailSent = true;
      } catch (error) {
        console.error(error);
        const airaError = new AiraError(error);
        this.$store.commit('PUSH_ERRORS', airaError.errorMessage);
      } finally {
        this.submitButtonDisabled = false;
      }
    },
    done() {
      window.location.href = 'https://aira.io/';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables/_colors.scss';
@import '../../styles/variables/_sizes.scss';
@import '../../styles/theme/_default.scss';

#password-reset {
  margin-top: 50px;
  .form {
    padding: 20px 20px 0px 20px;
  }
}
</style>
